.wp1790 {
    max-width: 1790px;
    margin: 0 auto;
    @media screen and (max-width: 1790px) {
        padding: 0 px2rem(30px);
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}
.wp1440 {
    max-width: 1440px;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
        padding: 0 px2rem(100px);
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}
.wp1400 {
    max-width: 1400px;
    margin: 0 auto;
    @media screen and (max-width: 1400px) {
        padding: 0 px2rem(60px);
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(30px);
    }
}

.more-btn {
    text-align: center;
    a {
        display: inline-block;
        background-color: #e60015;
        color: #fff;
        border-radius: 15px;
        padding: 8px 23px;
        line-height: 1;
        font-size: 14px;
        transition: all .3s;
        &:hover {
            background-color: #ff0017;
        }
        @media screen and (max-width: 1024px) {
            padding: px2rem(20px) px2rem(44px);
            border-radius: px2rem(35px);
            font-size: px2rem(22px);
        }
    }
}
.common-title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: px2rem(45px);
    color: $color333;
    text-align: center;
}

.page-banner {
    &-img {
        @include zoomImg(1903px, 340px);
        @media screen and (max-width: 1024px) {
            @include zoomImg(750px, 280px);
        }
        img {
            @include mbImg;
        }
    }
}

.page-nav {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-bottom: 1px solid #dcdcdc;
    @media screen and (max-width: 1024px) {
        height: px2rem(110px);
        // justify-content: flex-start;
        padding: 0 px2rem(40px);
        overflow-x: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &-item {
        display: flex;
        align-items: center;
        // @media screen and (max-width: 1024px) {
        //     margin: 0 auto;
        // }
        &:not(:last-child) {
            margin-right: px2rem(45px);
            &::after {
                content: '';
                width: 1px;
                height: 22px;
                background-color: #dcdcdc;
                margin-left: px2rem(45px);
            }
        }
        &:hover {
            a {
                color: $redColor;
                .nav-icon {
                    img:nth-of-type(1) {
                        opacity: 0;
                    }
                    img:nth-of-type(2) {
                        opacity: 1;
                    }
                }
            }
            a.diamond {
                color: $blueColor;
            }
        }
        a {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: $color333;
            transition: all .4s;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                white-space: nowrap;
            }
            .nav-icon {
                position: relative;
                img {
                    transition: all .4s;
                    margin-right: 13px;
                    &:nth-of-type(2) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        opacity: 0;
                    }
                }
            }
        }
    }
    .active {
        a {
            color: $redColor;
            .nav-icon {
                img:nth-of-type(1) {
                    opacity: 0;
                }
                img:nth-of-type(2) {
                    opacity: 1;
                }
            }
        }
        a.diamond {
            color: $blueColor;
        }
    }
}

.product-nav {
    .wp {
        @include wp(1200px);
    }

    &-box {
        padding: 0 px2rem(50px);
        position: relative;
    }
    &-swiper {
        overflow: hidden;
        box-sizing: border-box;
        .swiper-slide {
            width: auto;
            &:not(:last-child) {
                .product-nav-item {
                    margin-right: 20px;
                    &::after {
                        content: '';
                        width: 1px;
                        height: 10px;
                        background-color: #bbb;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    &-prev-btn,
    &-next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        outline: none;
        font-size: 18px;
        &:hover {
            color: $redColor;
        }
    }
    &-prev-btn {
        left: 0;
    }
    &-next-btn {
        right: 0;
    }

    &-item {
        font-size: 18px;
        display: flex;
        align-items: center;
        a {
            color: $color333;
            display: inline-block;
            &:hover {
                color: $redColor;
                font-weight: bold;
            }
        }
        a.diamond {
            &:hover {
                color: $blueColor;
                font-weight: bold;
            }
        }
    }
    .active {
        a {
            color: $redColor;
            font-weight: bold;
        }
        a.diamond {
            color: $blueColor;
            font-weight: bold;
        }
    }
}

.structure {
    padding: px2rem(80px) 0 px2rem(100px);
    .wp {
        @include wp(1200px);
    }
    .item {
        margin-bottom: 56px;
        &:nth-child(2) {
            .content {
                border-top: 1px dashed #d1ae85;
                padding-top: 47px;
            }
        }
    }
    .content {
        margin-top: 27px;
        img {
            width: 100%;
            object-fit: contain;
        }
    }
}

