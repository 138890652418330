.product {
    padding: px2rem(75px) 0 px2rem(81px);
    &-wrapper {
        .wp {
            @include wp(1200px);
        }
    }
    &-list {
        margin: 40px 0 0;
        font-size: 0;
        // padding-bottom: px2rem(40px);
        display: flex;
        flex-flow: wrap;
        li {
            overflow: hidden;
            box-sizing: border-box;
            vertical-align: top;
            width: 50%;
            margin-bottom: px2rem(40px);
            &:nth-child(odd) {
                padding-right: 20px;
            }
            &:nth-child(even) {
                padding-left: 20px;
            }
        }
        .wps {
            position: relative;
            padding-bottom: 88px;
            overflow: hidden;
            display: block;
            background-color: #eee;
            @media screen and (min-width: 1024px) {
                &:hover {
                    transition: all .4s;
                    .info {
                        height: 180px;
                        background: #eee;
                    }
                    .t {
                        color: #333;
                        font-weight: 700;
                    }
                    .p {
                        visibility: visible;
                        opacity: 1;
                        color: #666;
                    }
                    .more {
                        width: 110px;
                        height: 30px;
                        background-color: #e50010;
                        border-radius: 15px;
                        opacity: 1;
                    }
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            overflow: hidden;
            padding-bottom: percentage(435 / 580);
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .info {
            position: absolute;
            transition: all .4s;
            left: 0;
            bottom: 0;
            width: 100%;
            box-sizing: border-box;
            height: 88px;
            padding: 38px 54px 0;
            // padding: 25px 35px;
            overflow: hidden;
            text-align: center;
        }
        .t {
            text-align: center;
            font-size: 20px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 10px;
        }
        .p {
            visibility: hidden;
            opacity: 0;
            font-size: 16px;
            line-height: 25px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all .4s;
            @include multiEllipsis(3);
        }
        .more {
            margin: 0 auto;
            width: 101px;
            display: block;
            height: 30px;
            border: 1px solid #fff;
            border-radius: 15px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            line-height: 28px;
            opacity: 0;
            margin-top: 20px;
            transition: all .3s;
            &:hover {
                border-color: $baseColor;
                background: $baseColor;
            }
        }
        .triangle-case-list-con {
            padding: px2rem(100px) 0;
            overflow: hidden;
        }
        @media (max-width: 800px) {
            .info {
                padding: 15px;
            }
        }
        @media (max-width: 1024px) {
            margin-top: px2rem(60px);
            li {
                width: 50%;
                &:nth-child(2n+1) {
                    padding: 0 5px 0 0 !important;
                }
                &:nth-child(2n) {
                    padding: 0 0 0 5px !important;
                }
            }
            .info {
                padding: px2rem(30px) px2rem(15px);
                height: auto;
                position: static;
                background-color: #eee;
                .p {
                    font-size: px2rem(26px);
                    visibility: visible;
                    opacity: 1;
                    text-align: left;
                    max-height: px2rem(150px);
                }
            }
            .t {
                font-size: px2rem(28px);
                font-weight: bold;
            }
            .wps {
                padding-bottom: 0;
                &:hover {
                    .info {
                        height: 130px;
                    }
                }
            }
        }
    }
    .diamond {
        li {
            width: 1 / 3 * 100%;
            @media screen and (min-width: 1024px) {
                &:nth-child(odd) {
                    padding: 0;
                }
                &:nth-child(even) {
                    padding: 0;
                }
                &:nth-child(3n+1) {
                    padding-right: 20px;
                }
                &:nth-child(3n+2) {
                    padding: 0 10px;
                }
                &:nth-child(3n+3) {
                    padding-left: 20px;
                }
            }
            @media screen and (max-width: 1024px) {
                width: 50%;
            }
        }
        .img {
            @include zoomImg(380px, 430px);
        }
    }
}
.triangle-case-list-con {
    overflow: hidden;
    padding-bottom: px2rem(80px);
}