.news {
    padding: px2rem(60px) 0 px2rem(80px);
    .wp {
        @include wp(1200px);
    }
    &-head {
        display: flex;
        background-color: #f5f5f5;
        height: 446px;
        @media screen and (max-width: 1024px) {
            height: auto;
            flex-flow: column-reverse;
        }
        &-info {
            width: 500px;
            height: 100%;
            box-sizing: border-box;
            padding: 66px 64px 0;
            @media screen and (max-width: 1024px) {
                width: 100%;
                padding: px2rem(50px) px2rem(40px);
            }
            .title {
                font-size: 26px;
                line-height: 40px;
                color: $color333;
                @include multiEllipsis;
                max-height: 80px;
                transition: all .4s;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(40px);
                    line-height: 1.6;
                    max-height: px2rem(128px);
                }
            }
            .desc {
                margin-top: 20px;
                font-size: 16px;
                color: #818181;
                line-height: 35px;
                @include multiEllipsis(3);
                max-height: 105px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(30px);
                    line-height: 1.8;
                    margin-top: px2rem(25px);
                }
            }
            .more {
                margin-top: 42px;
                @media screen and (max-width: 1024px) {
                    margin-top: px2rem(60px);
                }
                span {
                    width: 110px;
                    height: 30px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color999;
                    color: #fff;
                    border-radius: 15px;
                    transition: all .4s;
                }
                &:hover {
                    span {
                        background-color: $baseColor;
                    }
                }
            }
        }
        &-img {
            width: 700 / 1200 * 100%;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                width: 100%;
                @include zoomImg(345px, 168px);
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all .4s;
            }
        }
        &:hover {
            .title {
                color: $baseColor;
            }
            img {
                transform: scale(1.05);
            }
        }
    }

    &-list {
        display: flex;
        flex-flow: wrap;
        margin-top: 40px;
        li {
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 60px;
            @media screen and (min-width: 1024px) {
                &:nth-child(odd) {
                    padding-right: 20px;
                }
                &:nth-child(even) {
                    padding-left: 20px;
                }
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-bottom: px2rem(80px);
            }
        }
        &-item {
            display: block;
            &:hover {
                .news-list-img {
                    img {
                        transform: scale(1.05);
                    }
                }
                .news-list-title {
                    color: $baseColor;
                }
            }
        }
        &-img {
            overflow: hidden;
            margin-bottom: px2rem(40px);
            @include zoomImg(580px, 370px);
        }

        &-date {
            font-size: 16px;
            color: $color999;
            font-family: Arial;
            margin-bottom: 7px;
        }

        &-title {
            font-size: 20px;
            color: #000;
            @include textEllipsis;
            margin-bottom: px2rem(20px);
            transition: all .4s;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(36px);
            }
        }

        &-desc {
            font-size: 16px;
            line-height: 30px;
            color: $color888;
            @include multiEllipsis;
            max-height: 80px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(28px);
                line-height: 2;
            }
        }
    }
}