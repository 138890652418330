// 导航
.header-nav,
.header-nav-mb {
    background: #fff;
    width: 100%;
    transition: all .5s;
    // position: fixed;
    // top: 0;
    // left: 0;
    z-index: 999;
    position: relative;
    top: 0;
    left: 0;
    // box-shadow: 1.5px 2px 2px 0px rgba(0, 0, 0, .1);
    .header-nav-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: px2rem(100px);
        padding-left: 13px;
        position: relative;
        @media screen and (max-width: 1440px) {
            padding: 0 20px;
        }
        @media screen and (max-width: 1024px) {
            padding: 0 px2rem(30px);
        }
        .header-logo {
            display: flex;
            align-items: center;
            img {
                width: px2rem(238px);
            }
        }
        .header-nav-box {
            display: flex;
            align-items: center;
            height: 100%;
            .search-btn {
                margin-right: px2rem(30px);
                .iconfont {
                    font-size: px2rem(48px);
                    color: $color333;
                }
            }
            li {
                margin-right: 60px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                @media screen and (max-width: 1024px) {
                    margin-right: 30px;
                }
                >a {
                    font-size: 16px;
                    color: #000;
                    transition: all .3s;
                    height: 100%;
                    display: inline-flex;
                    align-items: center;
                    box-sizing: border-box;
                }
                .active {
                    font-weight: bold;
                    color: $baseColor;
                }
                &:hover {
                    >a {
                        font-weight: bold;
                        color: $baseColor;
                    }
                    .hover-menu {
                        z-index: 99;
                        opacity: 1;
                        visibility: visible;
                        transform: translate(-50%, 0);
                    }
                }
                .hover-menu {
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    opacity: 0;
                    visibility: hidden;
                    transform: translate(-50%, 15px);
                    z-index: -1;
                    display: flex;
                    white-space: nowrap;
                    flex-flow: column;
                    background-color: rgba(255, 255, 255, .9);
                    transition: all .5s;
                    text-align: center;
                    a {
                        padding: 0 30px;
                        // margin-right: 50px;
                        font-size: 16px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $color333;
                        transition: all .3s;
                        &:not(:last-child) {
                            border-bottom: 1px solid rgba($color999, .15);
                        }
                        &:hover {
                            color: $baseColor;
                        }
                    }
                    a:last-of-type {
                        margin-right: 0;
                    }
                }
                .menu-btn {
                    color: #000;
                    font-size: 22px;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
            li:nth-last-of-type(2) {
                .hover-menu {
                    left: unset;
                    right: 0;
                    transform: translate(20%, 30%);
                }
                &:hover {
                    .hover-menu {
                        z-index: 99;
                        opacity: 1;
                        transform: translate(20%, 0);
                    }
                }
            }
            li:last-of-type {
                margin-right: 0;
                .hover-menu {
                    left: unset;
                    right: 0;
                    transform: translate(0, 30%);
                }
                &:hover {
                    .hover-menu {
                        z-index: 99;
                        opacity: 1;
                        transform: translate(0, 0);
                    }
                }
            }
            .search-box {
                display: flex;
                box-sizing: border-box;
                position: relative;
                align-items: center;
                &:hover {
                    // border-radius: 50%;
                    input {
                        border: 1px solid #000;
                        width: 140px;
                        &::placeholder {
                            color: #999;
                            font-size: 14px;
                        }
                    }
                }
                .header-search {
                    position: relative;
                    z-index: 10;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    .icon-sousuo {
                        font-size: 26px;
                    }
                    @media screen and (max-width: 750px) {
                        right: px2rem(45px);
                    }
                }
                input {
                    position: absolute;
                    right: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all .3s;
                    border: 1px solid transparent;
                    padding-right: 30px;
                    padding-left: 20px;
                    border-radius: 20px;
                    height: 40px;
                    width: 0;
                    @media screen and (max-width: 750px) {
                        height: px2rem(50px);
                        padding: 0 px2rem(20px);
                        right: px2rem(30px);
                    }
                }
            }
        }
    }
}

.header-nav {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
.header-nav-mb {
    display: none;
    // position: fixed;
    // top: 0;
    // left: 0;
    @media screen and (max-width: 1024px) {
        display: block;
    }
    .header-nav-box {
        .word {
            font-size: px2rem(28px);
            color: $color333;
            margin-left: px2rem(10px);
        }
        .search-btn {
            display: flex;
            align-items: center;
            .icon {
                width: px2rem(34px);
            }
        }
    }
    .search-wrapper {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 100;
        width: 100%;
        .content {
            display: flex;
            justify-content: center;
            padding: px2rem(41px) px2rem(30px) px2rem(49px);
            box-sizing: border-box;
            background-color: #f5f5f5;
            .search-box {
                display: flex;
                border: 1px solid #d8d7d7;
                background-color: #fff;
                width: 100%;
                border-radius: px2rem(45px);
                overflow: hidden;
                input {
                    height: px2rem(90px);
                    width: 87%;
                    border: none;
                    padding: 0 0 0 px2rem(40px);
                    box-sizing: border-box;
                    font-size: px2rem(26px);
                    color: #666;
                    &::placeholder {
                        font-size: px2rem(26px);
                        color: #999;
                    }
                }
                button {
                    background-color: transparent;
                    border: none;
                    font-size: px2rem(60px);
                    color: #999;
                }
            }
        }
        .search-mask {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, .5);
        }
    }
    .nav-menu {
        display: flex;
        align-items: center;
        .menu-icon {
            width: px2rem(34px);
            // height: px2rem(6px);
            // border-radius: px2rem(3px);
            // background-color: $color999;
            // position: relative;
            // &::after,
            // &::before {
            //     content: '';
            //     position: absolute;
            //     left: 0;
            //     width: px2rem(36px);
            //     height: px2rem(6px);
            //     border-radius: px2rem(3px);
            //     background-color: $color999;
            // }
            // &::after {
            //     top: px2rem(-13px);
            // }
            // &::before {
            //     bottom: px2rem(-13px);
            // }
        }
        .close {
            display: none;
            color: $color999;
            .iconfont {
                font-size: px2rem(34px);
            }
        }
    }
    .menu-btn-mb {
        .word {
            &::after {
                content: '导航';
            }
        }
    }
    .show-menu {
        .menu-icon {
            display: none;
        }
        .close {
            display: block;
        }
        .word {
            &::after {
                content: '关闭';
            }
        }
    }
    .menu-unfold {
        position: fixed;
        display: none;
        top: px2rem(100px);
        right: 0;
        z-index: 20;
        // width: 100%;
        // height: 100%;
        transition: all .3s;
        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
            top: px2rem(100px);
            left: 0;
            z-index: 19;
            background-color: rgba(0, 0, 0, .56);
        }
        .menu-box {
            position: fixed;
            top: px2rem(100px);
            right: 0;
            display: none;
            z-index: 20;
            .menu-item {
                width: px2rem(600px);
                display: flex;
                justify-content: space-between;
                flex-flow: column;
                color: #000;
                font-size: px2rem(32px);
                line-height: px2rem(100px);
                background-color: #f5f5f5;
                border-top: px2rem(1px) solid #95959541;
                box-sizing: border-box;
                margin-left: auto;
                .item {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 px2rem(30px) 0 px2rem(70px);
                    a {
                        color: #000;
                    }
                    .icon-add {
                        font-size: px2rem(34px);
                        color: $color333;
                        transition: all .3s;
                        font-weight: bold;
                        font-family: cursive !important;
                    }
                }
                .item:last-child {
                    .icon-add {
                        display: none;
                    }
                }
                .menu-2 {
                    display: none;
                    a {
                        padding: 0 px2rem(30px) 0 px2rem(70px);
                        border-top: px2rem(1px) solid #95959541;
                        display: block;
                        color: $color999;
                        font-size: px2rem(28px);
                    }
                }
            }
        }
    }
}

.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-110%);
    .header-nav-wrapper {
        height: px2rem(80px);
        @media screen and (max-width: 1024px) {
            height: px2rem(100px);
        }
    }
}

.header-i {
    display: block;
    width: 100%;
    @include zoomImg(1920px, 340px);
    img {
        transition: all .3s;
    }
}


.pc-toggle-menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    &.show {
        opacity: 1;
        visibility: visible;
        .menu-con {
            transform: translateX(0);
        }
    }
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .menu-con {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 460px;
        overflow: hidden;
        // background-image: url(../images/menu_bg1.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;
        background-color: #fff1dc;
        display: flex;
        box-sizing: border-box;
        padding: 80px 40px;
        transform: translateX(100%);
        transition: all .4s;
    }
    .close-btn {
        position: absolute;
        cursor: pointer;
        font-size: 30px;
        color: $redColor;
        font-weight: bold;
        top: 25px;
        right: 25px;
    }
    .menu-box {
        flex: 1;
        overflow-y: auto;
    }
    .menu-item {
        border-bottom: 2px solid #f7e8e8;
        padding: 25px 0 20px;
        &:last-child {
            border-bottom: none;
        }
        .dl {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: bold;
            a {
                color: #635340;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .dd {
            a {
                display: inline-block;
                vertical-align: top;
                font-size: 18px;
                margin: 0 20px 10px 0;
                color: #a0917d;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}