.share-box {
    display: flex;
    justify-content: center;
    // margin-top: px2rem(20px);
    align-items: center;
    .share-box-item {
        position: relative;
        .wechat-ewm {
            position: absolute;
            border-radius: 5px;
            border: 1px solid #eee;
            background: #fff;
            left: 50%;
            bottom: 32px;
            padding: 10px 10px 5px;
            display: none;
            transform: translateX(-50%);
            p {
                font-size: 12px;
                color: #333;
                text-align: center;
                margin-top: 5px;
            }
        }
        &:hover {
            .wechat-ewm {
                display: block;
            }
        }
    }
}
.wechat-tip-mask {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: none;
    background-image: url(../images/share_bg.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
}


