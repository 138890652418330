.news-detail {
    padding: px2rem(80px) 0 px2rem(100px);
    .wp {
        &:nth-child(1) {
            @include wp(1050px);
        }
        &:nth-child(2) {
            @include wp(1120px);
        }
    }
    &-title {
        font-size: 26px;
        color: #202020;
        line-height: 36px;
        text-align: center;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(38px);
            line-height: 1.5;
        }
    }
    &-data {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        @media screen and (max-width: 1024px) {
            flex-flow: column;
        }
        .box {
            font-size: 14px;
            color: #848484;
            display: flex;
            align-items: center;
            &:first-child {
                margin-right: 40px;
                @media screen and (max-width: 1024px) {
                    margin-right: 0;
                    margin-bottom: px2rem(30px);
                }
            }
            .item {
                display: flex;
                align-items: center;
                img {
                    margin-right: 6px;
                }
                &:first-child {
                    margin-right: 40px;
                }
            }
        }
        .share-box-item {
            width: 30px;
            height: 30px;
            background-color: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;
        }
    }

    &-content {
        padding-top: 40px;
        font-size: 18px;
        color: $color666;
        line-height: 35px;
        border-top: 1px dashed rgba($color888, .5);
        margin-top: 35px;
        @media screen and (max-width: 1024px) {
            margin-top: px2rem(40px);
            font-size: px2rem(32px);
            line-height: 2;
        }
        img {
            max-width: 100%;
        }
        video {
            max-width: 100%;
        }
        iframe {
            max-width: 100%;
        }
    }
    &-navigation {
        background-color: $color999;
        font-size: 15px;
        color: #fff;
        display: flex;
        // padding: 0 30px;
        line-height: 1;
        margin-top: 50px;
        @media screen and (max-width: 1024px) {
            padding: 0 px2rem(20px);
            font-size: px2rem(28px);
            margin-top: px2rem(70px);
        }
        .iconfont {
            color: #fff;
            transition: all .3s;
        }
        a {
            padding: px2rem(18px) 35px;
            transition: all .3s;
            color: #fff;
            @media screen and (max-width: 1024px) {
                padding: px2rem(25px) 0;
            }
            &:hover {
                background-color: #818181;
            }
        }
    }
    &-back-btn {
        flex: 1;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
            font-size: 20px;
            margin-right: px2rem(20px);
        }
    }
    &-prev-btn,
    &-next-btn {
        p {
            @include textEllipsis;
            flex: 1;
        }
        display: flex;
        align-items: center;
        width: 34%;
        @media screen and (max-width: 1024px) {
            p {
                i {
                    display: none;
                }
                span {
                    display: none;
                }
            }
        }
    }
    &-prev-btn {
        padding-left: 0;
        .iconfont {
            margin-right: px2rem(20px);
        }
    }
    &-next-btn {
        padding-right: 0;
        @media screen and (max-width: 1024px) {
            text-align: right;
            .iconfont {
                margin-left: px2rem(20px);
            }
        }
    }
}