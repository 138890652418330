.footer {
    background-color: #f5f5f5;
    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        padding: px2rem(70px) px2rem(30px) px2rem(45px);
        @media screen and (max-width: 1024px) {
            justify-content: center;
            padding: px2rem(70px) 0;
        }
        .left {
            .footer-link {
                display: flex;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
                .link-item {
                    margin-right: 125px;
                    @media screen and (max-width: 1024px) {
                        margin-right: 50px;
                    }
                    a {
                        display: block;
                        color: $color666;
                        font-size: 16px;
                        margin-bottom: 13px;
                        transition: all .3s;
                        &:hover {
                            color: $baseColor;
                        }
                    }
                    a:first-of-type {
                        font-size: 18px;
                        color: #000;
                        margin-bottom: 23px;
                        &:hover {
                            color: $baseColor;
                        }
                    }
                }
                .link-item:last-child {
                    margin-right: 0;
                }
            }
        }

        .right {
            .contact-wrapper {
                .share-box {
                    display: flex;
                    justify-content: flex-end;
                    @media screen and (max-width: 1024px) {
                        justify-content: center;
                    }
                    .share-box-item {
                        margin-right: 35px;
                        @media screen and (max-width: 1024px) {
                            margin-right: px2rem(100px);
                        }
                        img {
                            transition: all .3s;
                            cursor: pointer;
                            width: 21px;
                            @media screen and (max-width: 1024px) {
                                width: px2rem(52px);
                            }
                            &:hover {
                                opacity: .7;
                            }
                        }
                    }
                    .share-box-item:last-child {
                        margin-right: 0;
                    }
                    img {
                        width: px2rem(14px);
                    }
                }
                .phone-box {
                    margin-top: 20px;
                    text-align: right;
                    @media screen and (max-width: 1024px) {
                        text-align: center;
                        margin-top: px2rem(30px);
                    }
                    .phone-num {
                        font-size: 30px;
                        color: #3d4144;
                        font-family: 'Arial';
                        font-weight: bold;
                        margin-bottom: 3px;
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(45px);
                            margin-bottom: 0;
                        }
                    }
                    .w {
                        font-size: 14px;
                        color: $color999;
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(20px);
                        }
                        span {
                            letter-spacing: 25px;
                            @media screen and (max-width: 1024px) {
                                letter-spacing: px2rem(36px);
                            }
                        }
                    }
                }

                .qrcode-box {
                    display: flex;
                    margin-top: px2rem(30px);
                    @media screen and (max-width: 1024px) {
                        justify-content: center;
                    }
                    .qrcode-item {
                        margin-right: px2rem(20px);
                        .qrcode-img {
                            img {
                                width: px2rem(130px);
                            }
                        }

                        .qrcode-title {
                            font-size: 14px;
                            text-align: center;
                            color: $color999;
                            margin-top: 6px;
                            @media screen and (max-width: 1024px) {
                                font-size: px2rem(20px);
                                margin-top: px2rem(4px);
                                line-height: px2rem(30px);
                            }
                            span {
                                @media screen and (max-width: 1024px) {
                                    display: block;
                                }
                            }
                        }
                    }
                    .qrcode-item:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .copyright-wrapper {
        border-top: 1px solid #e5e5e5;
        .content {
            padding-top: px2rem(47px);
            padding-bottom: px2rem(57px);
            font-size: 16px;
            color: $color999;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(20px);
                line-height: px2rem(40px);
                text-align: center;
            }
            span {
                @media screen and (max-width: 1024px) {
                    display: block;
                    white-space: nowrap;
                }
            }
            a {
                color: $color999;
                img {
                    margin-left: px2rem(10px);
                    vertical-align: middle;
                    @media screen and (max-width: 1024px) {
                        width: px2rem(22px);
                        vertical-align: text-top;
                    }
                }
            }
            .link {
                @media screen and (max-width: 1024px) {
                    display: block;
                }
                text-align: center;
            }
        }
    }
}