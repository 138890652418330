.index-banner {
    .index-banner-content {
        .banner-swiper-pc {
            overflow: hidden;
            position: relative;

            .swiper-wrapper {
                .swiper-slide {
                    overflow: hidden;

                    .banner-img {
                        display: block;
                        width: 100%;
                        @include zoomImg(1903px, 860px);
                        @media screen and (max-width: 1024px) {
                            @include zoomImg(750px, 480px);
                        }
                        img {
                            transition: all .3s;
                            @include mbImg;
                        }
                    }
                }
            }

            .banner-swiper-pagination {
                position: absolute;
                text-align: center;
                bottom: 30px;
                z-index: 10;
                @media screen and (max-width: 1024px) {
                    bottom: px2rem(20px);
                }
                .swiper-pagination-bullet {
                    width: px2rem(15px);
                    height: px2rem(5px);
                    background-color: #fff;
                    border-radius: px2rem(3px);
                    opacity: 1;
                    transition: all .3s;
                    margin: 0 px2rem(7px);
                }

                .swiper-pagination-bullet-active {
                    width: px2rem(20px);
                    background-color: #c9030a;
                }
            }
        }
    }
}

.index-brand {
    .brand-wrapper {
        .brand-item {
            display: flex;
            height: 620px;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
                height: auto;
            }
            .left {
                width: 50%;
                // padding: 100 / 1903 * 100% 195 / 1903 * 100% 0;
                padding: px2vw(100) px2vw(195) 0;
                box-sizing: border-box;
                height: 100%;
                // @media screen and (max-width: 1440px) {
                //     padding: 60 / 1903 * 100% 145 / 1903 * 100% 0;
                // }
                // @media screen and (max-width: 1024px) {
                //     padding: 30 / 1903 * 100% 145 / 1903 * 100% 0;
                // }
                @media screen and (max-width: 1024px) {
                    padding: px2rem(100px) px2rem(30px);
                }

                .content {
                    width: 100%;
                    text-align: center;

                    .logo {
                        margin-bottom: 30px;
                        @media screen and (max-width: 1024px) {
                            margin-bottom: 15px;
                        }
                        img {
                            @media screen and (max-width: 1024px) {
                                width: px2rem(110px);
                            }
                        }
                        @media screen and (max-width: 1440px) {
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 1024px) {
                            margin-bottom: px2rem(30px);
                        }
                    }

                    .desc {
                        font-size: 16px;
                        line-height: 35px;
                        color: $color666;
                        margin-bottom: 40px;
                        @media screen and (max-width: 1440px) {
                            margin-bottom: 30px;
                        }
                        @media screen and (max-width: 1024px) {
                            line-height: 26px;
                            margin-bottom: 15px;
                            font-size: 14px;
                        }
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(28px);
                            line-height: px2rem(45px);
                            margin-bottom: px2rem(50px);
                        }
                    }
                }
            }

            .right {
                width: 50%;
                height: 100%;
                .content {
                    width: 100%;
                    position: relative;
                    height: 100%;
                    .img-box {
                        // @include zoomImg(951px, 620px);
                        height: 100%;
                        overflow: hidden;
                        @media screen and (max-width: 1024px) {
                            @include zoomImg(750px, 620px);
                        }
                        img {
                            transition: all .8s;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }

                        &:hover {
                            img {
                                transform: scale(1.07);
                            }
                        }
                    }

                    .counter-wrapper {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        border-right: 1px solid rgba(255, 255, 255, .1);
                        display: flex;
                        flex-flow: column;
                        justify-content: space-evenly;
                        padding: 0 48px;
                        width: 252px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1024px) {
                            width: 100%;
                            height: auto;
                            flex-flow: row;
                            padding: px2rem(38px) 0;
                            border-bottom: 1px solid rgba(255, 255, 255, .1);
                            border-right: none;
                            justify-content: space-around;
                        }
                        .counter-item {
                            .title {
                                font-size: 16px;
                                color: $color666;
                                line-height: 1;
                                margin-bottom: px2rem(10px);
                                @media screen and (max-width: 1024px) {
                                    font-size: px2rem(20px);
                                }
                            }

                            .num-box {
                                color: $color666;
                                line-height: 1;

                                .num {
                                    font-size: 60px;
                                    font-family: 'Bebas';
                                    display: inline-block;
                                    @media screen and (max-width: 1024px) {
                                        font-size: px2rem(70px);
                                    }
                                }

                                .s {
                                    vertical-align: bottom;
                                    font-size: 16px;
                                    @media screen and (max-width: 1024px) {
                                        font-size: px2rem(20px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .left,
            .right {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
        .brand-item:nth-child(even) {
            flex-flow: row-reverse;
            @media screen and (max-width: 1024px) {
                flex-flow: column-reverse;
            }
        }
    }
}

.history {
    .history-wrapper {
        background-repeat: no-repeat;
        background-size: cover;
        // height: 1000px;
        padding-top: px2rem(90px);
        padding-bottom: px2rem(129px);
        @media screen and (max-width: 1024px) {
            padding-bottom: px2rem(150px);
        }
        .history-swiper-wrapper {
            background-image: url(../images/bg4.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-top: px2rem(58px);
            height: px2rem(662px);
            position: relative;
            @media screen and (max-width: 1024px) {
                height: px2rem(730px);
            }
            @media screen and (max-width: 1024px) {
                background-image: url(../images/bg5.png);
            }
            .wp {
                @include wp(1440px);
            }
            .swiper-box {
                overflow: hidden;
                height: 100%;
                position: relative;
                @media screen and (max-width: 1024px) {
                    padding: 0 px2rem(75px);
                }
                .swiper-wrapper {

                    // justify-content: center;
                    .swiper-slide {
                        position: relative;
                        // margin-right: -40px;
                        transition: all .5s ease-out;

                        &::after {
                            content: '';
                            position: absolute;
                            width: px2rem(10px);
                            height: px2rem(10px);
                            border-radius: 50%;
                            background-color: #7f7b73;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, 50%);
                        }

                        .index-history-content {
                            position: relative;
                            display: flex;
                            flex-flow: column;
                            min-width: 100%;

                            .history-img {
                                @include zoomImg(320px, 200px);
                                width: 100%;
                                overflow: hidden;

                                &:hover {
                                    img {
                                        transform: scale(1.05);
                                    }
                                }

                                img {
                                    transition: all .3s;
                                }
                            }

                            .info-box {
                                text-align: center;

                                .year-box {
                                    color: $baseColor;
                                    font-size: 18px;
                                    font-weight: bold;
                                    margin-top: 10px;
                                    margin-bottom: 5px;
                                    @media screen and (max-width: 1024px) {
                                        margin-top: px2rem(14px);
                                        margin-bottom: px2rem(10px);
                                    }
                                    span:first-child {
                                        font-size: px2rem(45px);
                                        font-family: 'Arial';
                                    }
                                }

                                .desc {
                                    font-size: 16px;
                                    color: $color333;
                                    @media screen and (max-width: 1024px) {
                                        font-size: px2rem(26px);
                                    }
                                }
                            }
                        }
                    }

                    .swiper-slide-prev {
                        // transform: scale(0);
                        opacity: 0;
                    }

                    .hide {
                        // transform: scale(0);
                        opacity: 0;
                    }

                    .swiper-slide:nth-child(even) {
                        .index-history-content {
                            position: absolute;
                            top: 53.5%;
                            flex-flow: column-reverse;
                            @media screen and (max-width: 1024px) {
                                top: 56.5%;
                            }
                            .info-box {
                                .year-box {
                                    margin-bottom: 10px;
                                    margin-top: 5px;
                                    @media screen and (max-width: 1024px) {
                                        margin-bottom: px2rem(10px);
                                        line-height: 1;
                                    }
                                }

                                .desc {
                                    margin-bottom: 20px;
                                    @media screen and (max-width: 1024px) {
                                        margin-bottom: px2rem(20px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .history-prev-btn,
            .history-next-btn {
                position: absolute;
                outline: none;
                z-index: 20;
                top: 50%;
                transform: translateY(-25%);
                background-color: $baseColor;
                color: #fff;
                border-radius: 50%;
                padding: 8px;
                cursor: pointer;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(26px);
                    padding: px2rem(12px);
                    transform: translateY(-30%);
                }
            }

            .swiper-button-disabled {
                display: none;
            }

            .history-prev-btn {
                left: 135 / 1903 * 100%;
                @media screen and (max-width: 1440px) {
                    left: 50 / 1903 * 100%;
                }
            }

            .history-next-btn {
                right: 135 / 1903 * 100%;

                @media screen and (max-width: 1440px) {
                    right: 50 / 1903 * 100%;
                }
            }
        }
    }
}

.index-base {
    .base-wrapper {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        padding-bottom: 832 / 1903 * 100%;
        display: flex;
        justify-content: center;
        position: relative;
        @media screen and (max-width: 1024px) {
            padding-bottom: 832 / 750 * 100%;
            @include mbBg;
        }
        .common-title {
            position: absolute;
            top: 110px;
            color: #fff;
            @media screen and (max-width: 1024px) {
                top: px2rem(70px);
            }
        }
    }
}

.index-news {
    background-repeat: no-repeat;
    background-image: url(../images/bg3.jpg);
    background-size: auto;
    background-position: top;
    padding-bottom: px2rem(100px);
    @media screen and (max-width: 1024px) {
        background-image: url(../images/bg7.jpg);
        background-size: contain;
    }
    .news-wrapper {
        padding-top: px2rem(45px);
        @media screen and (max-width: 1024px) {
            padding: px2rem(30px) 0 0 px2rem(30px);
        }
        .news-swiper-wrapper {
            position: relative;
            margin-top: px2rem(45px);
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                margin-top: px2rem(34px);
                padding-bottom: px2rem(90px);
            }
            .swiper-wrapper {
                @media screen and (max-width: 1024px) {
                    padding-right: px2rem(30px);
                }
                .swiper-slide {
                    .news-content {
                        display: block;
                        &:hover {
                            .news-img {
                                img {
                                    transform: scale(1.08);
                                }
                            }
                            .news-title {
                                color: $baseColor;
                            }
                        }
                        .news-img {
                            width: 100%;
                            @include zoomImg(440px, 282px);
                            overflow: hidden;
                            img {
                                transition: all .5s;
                            }
                        }

                        .news-title {
                            font-size: 20px;
                            color: #000;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-top: 25px;
                            margin-bottom: 20px;
                            padding-right: 30px;
                            transition: all .3s;
                            @media screen and (max-width: 1024px) {
                                font-size: px2rem(28px);
                                padding-right: 0;
                                margin-top: px2rem(18px);
                                margin-bottom: px2rem(18px);
                            }
                        }

                        .news-desc {
                            @include multiEllipsis(2);
                            font-size: 16px;
                            color: $color888;
                            line-height: 30px;
                            margin-bottom: 20px;
                            height: 60px;
                            padding-right: 30px;
                            @media screen and (max-width: 1024px) {
                                font-size: px2rem(24px);
                                line-height: px2rem(35px);
                                height: auto;
                                padding-right: 0;
                                margin-bottom: px2rem(6px);
                            }
                        }

                        .news-date {
                            font-size: 16px;
                            color: $color999;
                            @media screen and (max-width: 1024px) {
                                font-size: px2rem(22px);
                            }
                        }
                    }
                }
                .swiper-slide:last-child {
                    margin-right: 0 !important;
                }
            }
            .index-news-pagination {
                position: absolute;
                text-align: center;
                bottom: 0;
                @media screen and (min-width: 768px) {
                    display: none;
                }
                .swiper-pagination-bullet {
                    width: px2rem(14px);
                    height: px2rem(14px);
                    background-color: #bfbfbf;
                    opacity: 1;
                }
                .swiper-pagination-bullet-active {
                    background-color: $baseColor;
                }
            }
        }
    }
}