
.brand-diamond-logo {
    display: flex;
    align-items: center;

    img {
        width: px2rem(34px);
    }

    // .i {
    //     display: none;
    // }
}

// 右侧商店
// <div class="right-fixed">
//     <div class="shop">
//         <a href="#">
//             <img src="../../images/tianmao.png" alt="">
//             <div>天猫商城</div>
//         </a>
//     </div>
//     <div class="shop">
//         <a href="#">
//             <img src="../../images/jindon.png" alt="">
//             <div>京东商城</div>
//         </a>
//     </div>
// </div>

.right-fixed {
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    color: #333;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    @media screen and (max-width: 1024px) {
        font-size: px2rem(24px);
        line-height: 2.2;
        transform: translate(0, 0);
        top: 70%;
    }
    .shop {
        margin: 2px 0;
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        border-radius: 10px;
        border: solid 1px #ddd;
        @media screen and (max-width: 1024px) {
            width: px2rem(120px);
            height: px2rem(120px);
            border-radius: px2rem(10px);
        }
        a {
            display: block;
        }
        img {
            width: 33px;
            padding-top: 10px;
            @media screen and (max-width: 1024px) {
                width: px2rem(40px);
            }
        }
    }
}


// 二级导航
.diamond-nav {
    padding-bottom: 40px;

    .nav-ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            text-align: center;
            font-size: 18px;
            line-height: 35px;
            color: #333;
            margin: 0 21px;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                width: 1px;
                height: 10px;
                background: #ddd;
                right: -21px;
                top: 10px;
            }

            &:last-child::before {
                content: none;
            }

            &:hover a {
                color: $redColor;
                font-weight: 700;
            }

            .on {
                color: $redColor;
                font-weight: 700;
            }

        }
    }
}