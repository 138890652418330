.story {
    padding: px2rem(65px) 0 0;
    .wp {
        @include wp(1200px);
    }
    &-content {
        font-size: 18px;
        color: $color666;
        line-height: 35px;
        border-top: 1px dashed rgba($color666, .4);
        padding-top: 40px;
        padding-bottom: px2rem(60px);
        margin-top: 25px;
        img {
            max-width: 100%;
        }
        table {
            width: 100%;
        }
    }
    &-flex {
        display: flex;
        text-align: center;
        @media screen and (max-width: 1024px) {
            flex-flow: column;
        }
        .item {
            margin-right: 10px;
            @media screen and (max-width: 1024px) {
                margin-right: 0;
                margin-bottom: px2rem(40px);
            }
        }
        .title {
            font-weight: bold;
            font-size: 16px;
            color: $color333;
            margin-top: 9px;
            line-height: 25px;
            p {
                font-weight: bold;
            }
        }
    }

    .diamond-flex {
        display: flex;
        padding-bottom: 50px;
        border-bottom: 1px dashed rgba($color666, .4);
        @media screen and (max-width: 1024px) {
            flex-flow: column;
        }
        .item {
            width: 50%;
            box-sizing: border-box;
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-bottom: px2rem(30px);
            }
            &:nth-child(1) {
                padding-right: 74px;
                @media screen and (max-width: 1024px) {
                    padding-right: 0;
                }
            }
        }
    }

    &-history {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 730px;
        padding: px2rem(65px) 0 px2rem(100px);
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            height: auto;
        }
        &-content {
            display: flex;
            margin-top: 26px;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
            }
            .img-box {
                width: 580 / 1200 * 100%;
                height: 470px;
                overflow: hidden;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    height: auto;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: all .4s;
                }
                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
            .right {
                width: 620px;
                background-color: #fff;
                padding: 40px 32px 0 60px;
                box-sizing: border-box;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding: px2rem(60px) 0 px2rem(60px) px2rem(30px);
                }
            }
            .info-box {
                position: relative;
                z-index: 5;
                &::after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    width: 2px;
                    background-color: #bfbfbf;
                    height: 300px;
                    // height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: -1;
                    @media screen and (max-width: 1024px) {
                        height: px2rem(630px);
                    }
                }
                .scroll-box {
                    height: 350px;
                    overflow: hidden;
                    padding-left: 30px;
                    @media screen and (max-width: 1024px) {
                        overflow-y: auto;
                        padding-right: px2rem(30px);
                    }
                    .item {
                        display: flex;
                        align-items: baseline;
                    }
                    .bscroll-indicator {
                        background-color: #bfbfbf !important;
                    }
                    .bscroll-vertical-scrollbar {
                        right: -23px !important;
                        pointer-events: auto !important;
                    }
                    .year {
                        font-weight: bold;
                        color: $color333;
                        font-size: 18px;
                        width: 110px;
                        flex-shrink: 0;
                        position: relative;
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(32px);
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            border: 2px solid #bfbfbf;
                            background-color: #fff;
                            border-radius: 50%;
                            box-sizing: border-box;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -29px;
                            z-index: 10;
                        }
                    }

                    .desc {
                        font-size: 18px;
                        color: $color666;
                        line-height: 50px;
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(32px);
                            line-height: 2;
                        }
                    }
                }
            }
        }
        &.diamond {
            height: 800px;
            @media screen and (max-width: 1024px) {
                height: auto;
            }
            .story-history-content {
                .img-box {
                    height: 540px;
                    @media screen and (max-width: 1024px) {
                        height: auto;
                    }
                }
                .right {
                    padding: 40px 20px 0 60px;
                    @media screen and (max-width: 1024px) {
                        padding: px2rem(60px) 0 px2rem(60px) px2rem(30px);
                    }
                }
                .info-box {
                    &::after {
                        height: 400px;
                        @media screen and (max-width: 1024px) {
                            height: px2rem(830px);
                        }
                    }
                    .scroll-box {
                        height: 450px;
                        .year {
                            width: 90px;
                        }
                        .bscroll-vertical-scrollbar {
                            right: -10px !important;
                            pointer-events: auto !important;
                        }
                    }
                }
            }
        }
    }
}

.channel {
    position: fixed;
    right: 0;
    top: 40%;
    // transform: translateY(-50%);
    width: 120px;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    z-index: 20;
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        font-family: 'Source Han Sans CN';
        color: #fff;
        height: 60px;
        line-height: 60px;
        background-color: $redColor;
        border-radius: 10px 10px 0 0;
        &.diamond {
            background-color: $blueColor;
        }
    }

    &-wrapper {
        // border-radius: 0 0 10px 10px;
        overflow: hidden;
        border-width: 0 1px;
        border-style: solid;
        border-color: #ddd;
        max-height: 390px;
        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 130px;
            background-color: #f5f5f5;
            flex-flow: column;
            transition: all .4s;
            border-bottom: 1px solid #ddd;
            box-sizing: border-box;
            &:hover {
                background-color: #e2e2e2;
            }
            &:last-child:nth-child(3) {
                border-radius: 0 0 10px 10px;
            }
            &:last-child {
                border-radius: 0 0 10px 10px;
            }
            .name {
                font-size: 14px;
                line-height: 20px;
                color: $color333;
                margin-top: 6px;
            }
        }
    }
    .unfold {
        display: none;
        text-align: center;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        background-color: #f5f5f5;
        justify-content: center;
        align-items: center;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: #ddd;
        color: $color333;
        font-size: 14px;
        border-radius: 0 0 10px 10px;
        .iconfont {
            color: $color333;
            font-size: 14px;
            margin-left: 5px;
            transition: all .4s;
            display: inline-block;
        }
        &:hover {
            background-color: #e2e2e2;
        }
    }
}

.mb-channel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000, .5);
    z-index: 99;
    display: none;
    &-box {
        white-space: nowrap;
        padding: px2rem(40px) px2rem(30px) px2rem(40px);
        display: flex;
        box-sizing: border-box;
        width: 100%;
        overflow-x: auto;
        .item {
            display: inline-block;
            text-align: center;
            flex-shrink: 0;
            width: 1 / 3 * 100%;
            img {
                width: px2rem(78px);
            }
            .name {
                font-size: px2rem(22px);
                line-height: 1.3;
                color: $color333;
                margin-top: px2rem(10px);
            }
        }

    }
    &-wrapper {
        width: 92vw;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: px2rem(10px);
        .close-btn {
            position: absolute;
            top: px2rem(15px);
            right: px2rem(15px);
            color: $redColor;
            font-weight: bold;
            font-size: px2rem(32px);
        }
    }
    &-btn {
        position: fixed;
        width: px2rem(80px);
        height: px2rem(80px);
        border-radius: 50%;
        background-color: rgba(#000, .5);
        bottom: px2rem(60px);
        right: px2rem(15px);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(22px);
        color: #fff;
        flex-flow: column;
        z-index: 98;
        @media screen and (min-width: 1024px) {
            display: none;
        }
    }
}

.fold-channel {
    .channel-wrapper {
        height: auto;
        max-height: none;
    }
    .unfold {
        .iconfont {
            transform: rotate(180deg);
        }
    }
}