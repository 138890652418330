.recru {
    padding: px2rem(70px) 0 px2rem(80px);

    .wp {
        @include wp(1200px);
    }

    &-list {
        margin-bottom: 50px;

        li:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    // &-list-item {
    //     @include anime(fadeInUp);
    // }

    &-list-head {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 20px 20px 20px 21px;
        background-color: #f8f8f8;
        line-height: 1;
        transition: all .3s;
        border: 1px solid #e0e0e0;

        @media screen and (max-width: 1024px) {
            padding: 16px px2rem(20px);
        }

        @media (any-hover: hover) {
            &:hover {
                background-color: $color999;

                .recru-list-box {
                    color: #fff;
                }

                .recru-list-arrow {
                    background-color: #fff;
                    border-color: #fff;
                }
            }
        }
    }

    &-list-head-active {
        background-color: $color999;

        .recru-list-box {
            color: #fff;
        }

        .recru-list-arrow {
            background-color: #fff;
            border-color: #fff;
        }
    }

    &-list-box {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 18px;
        color: $color333;

        @media screen and (max-width: 1024px) {
            font-size: px2rem(32px);
        }

        span {
            display: inline-block;
            // flex: 1;
            transition: all .3s;

            &:nth-of-type(1) {
                width: 238 / 1111 * 100%;

                @media screen and (max-width: 1024px) {
                    flex: 1;
                }
            }

            &:nth-of-type(2) {
                width: 262 / 1111 * 100%;

                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }

            &:nth-of-type(3) {
                width: 249 / 1111 * 100%;

                @media screen and (max-width: 1024px) {
                    flex: 1;
                    display: none;
                }
            }

            &:nth-of-type(4) {
                width: 368 / 1111 * 100%;

                @media screen and (max-width: 1024px) {
                    flex: 1;
                    display: none;
                }
            }
        }
    }

    &-list-arrow {
        color: #787c81;
        width: 26px;
        height: 26px;
        box-sizing: border-box;
        border: 2px solid #787c81;
        border-radius: 50%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        font-size: 14px;
        // padding: 0 px2rem(20px);
        transition: all .3s;

        @media screen and (max-width: 1024px) {
            font-size: px2rem(24px);
            width: px2rem(40px);
            height: px2rem(40px);
        }
    }

    &-list-title {
        font-size: 18px;
        color: #000;
        line-height: 1;
        padding-left: 15px;

        @media screen and (max-width: 1024px) {
            font-size: px2rem(32px);
            padding-left: px2rem(20px);
        }
    }

    &-list-content {
        padding: 65px 35px 50px 25px;
        display: none;
        border: 1px solid #e0e0e0;
        border-top: none;
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            padding: px2rem(30px) px2rem(30px) px2rem(60px);
        }

        .recru-list-head-mb {
            display: none;

            @media screen and (max-width: 1024px) {
                display: block;
                font-size: px2rem(30px);
                line-height: 2;
                padding-bottom: px2rem(40px);
                margin-bottom: px2rem(40px);
                border-bottom: px2rem(1px) dashed rgba(#000, .3);
            }
        }

        .content-box {
            display: flex;
            flex-flow: wrap;
            @media screen and (max-width: 1024px) {
                flex-flow: column;
            }
            .content-item {
                font-size: 18px;
                color: #000;
                line-height: 35px;
                width: 50%;
                box-sizing: border-box;
                margin-bottom: 60px;
                @media screen and (min-width: 1024px) {
                    &:last-child,
                    &:nth-last-child(2) {
                        margin-bottom: 0;
                    }
                    &:nth-child(odd) {
                        border-right: 1px dashed #e0e0e0;
                        padding-right: 60px;
                    }
                    &:nth-child(even) {
                        padding-left: 60px;
                    }
                }
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(32px);
                    line-height: 2;
                    width: 100%;
                    margin-bottom: px2rem(80px);
                }

                .title {
                    font-size: 26px;
                    color: $color444;
                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(48px);
                    }
                }

                .content {
                    font-size: 16px;
                    color: $color666;
                    margin-top: 10px;

                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(28px);
                    }
                }
            }
        }

        .contact {
            font-size: 16px;
            line-height: 35px;
            color: #000;
            font-weight: bold;
            margin-top: 20px;
        }

        .recru-btn {
            margin-top: px2rem(50px);
            a {
                border-radius: 20px;
                display: inline-block;
                font-size: 15px;
                color: #fff;
                line-height: 1;
                background-color: $color999;
                padding: 13px 48px;
                transition: all .4s;

                &:hover {
                    background-color: $redColor;
                }

                @media screen and (max-width: 1024px) {
                    padding: px2rem(24px) px2rem(60px);
                    font-size: px2rem(30px);
                }
            }
        }
    }
}