.history {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left bottom;
    @media screen and (max-width: 1024px) {
        background-size: auto;
    }
    .wp {
        @include wp(800px);
    }

    &-content {
        position: relative;
        z-index: 20;
        padding-bottom: 30px;
        margin-top: 35px;
        @media screen and (max-width: 1024px) {
            padding-left: px2rem(80px);
        }
        &::after {
            content: '';
            width: 2px;
            height: 100%;
            background-color: #dcdcdc;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            @media screen and (max-width: 1024px) {
                left: px2rem(18px);
                transform: translateX(0) scaleY(.99);
                transform-origin: 0 100%;
            }
        }
        &::before {
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $redColor;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            @media screen and (max-width: 1024px) {
                left: 1.1%;
                transform: translateX(0);
            }
        }
    }

    &-item {
        display: flex;
        @media screen and (min-width: 1024px) {
            &:nth-child(odd) {
                flex-flow: row-reverse;
            }
            &:nth-child(even) {
                .history-box {
                    text-align: right;
                    .year {
                        .dot {
                            &::before {
                                left: auto;
                                right: 100%;
                            }
                        }
                    }
                }
            }
            &:not(:first-child) {
                margin-top: -100px;
            }
        }
        @media screen and (max-width: 1024px) {
            margin-bottom: px2rem(100px);
        }
        &:last-child {
            .history-box {
                .img-box {
                    height: auto;
                }
            }
        }
    }

    &-box {
        width: 320px;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
        .year {
            font-size: 22px;
            color: $redColor;
            font-weight: bold;
            // position: relative;
            margin-bottom: 6px;
            .dot {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 2px solid $redColor;
                position: absolute;
                background-color: #fff;
                left: 50%;
                transform: translateX(-50%);
                z-index: 10;
                @media screen and (max-width: 1024px) {
                    width: px2rem(40px);
                    height: px2rem(40px);
                    left: 0;
                    transform: translateX(0) translateY(25%);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: $redColor;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    @media screen and (max-width: 1024px) {
                        width: px2rem(20px);
                        height: px2rem(20px);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: $redColor;
                    width: 50px;
                    top: 50%;
                    left: 100%;
                    transform: translateY(-50%);
                    @media screen and (max-width: 1024px) {
                        width: px2rem(30px);
                    }
                }
            }
        }

        .desc {
            font-size: 18px;
            color: $color333;
            margin-bottom: 17px;
        }
        .img-box {
            width: 100%;
            height: 200px;
            @media screen and (max-width: 1024px) {
                height: auto;
            }
        }
        img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}