.address {
    padding: px2rem(75px) 0 px2rem(100px);
    .wp {
        @include wp(1200px);
    }

    &-wrapper {
        padding: 50px 50px 60px;
        border: 1px solid #eee;
        @media screen and (max-width: 1024px) {
            padding: 0;
            border: none;
        }
    }
    &-box {
        display: flex;
        width: 100%;
        @media screen and (max-width: 1024px) {
            flex-flow: column;
        }
    }
    &-item {
        background-color: #f5f5f5;
        min-height: 280px;
        box-sizing: border-box;
        padding: 40px 25px 50px 49px;
        width: 50%;
        border: 3px solid #f5f5f5;
        cursor: pointer;
        @media screen and (max-width: 1024px) {
            width: 100%;
            height: auto;
            padding: px2rem(50px) px2rem(30px) px2rem(30px);
        }
        &:first-child {
            margin-right: 10px;
            @media screen and (max-width: 1024px) {
                margin-right: 0;
                margin-bottom: px2rem(50px);
            }
        }
        &:hover {
            border-color: #ddd;
        }
        .title {
            font-size: 22px;
            color: $color666;
            font-weight: bold;
            border-bottom: 1px dashed #ddd;
            padding-bottom: 20px;
            margin-bottom: 17px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(38px);
            }
        }
        .info {
            .item {
                display: flex;
                align-items: baseline;
                line-height: 40px;
                color: $color666;
                font-size: 18px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(32px);
                    line-height: 2;
                }
                img {
                    margin-right: 8px;
                }
            }
        }
    }
    .active {
        border-color: #ddd;
    }
    .map {
        width: 100%;
        height: 520px;
        margin-top: 30px;
        @media screen and (max-width: 1024px) {
            height: px2rem(600px);
        }
    }
}