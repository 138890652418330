.about {
    .wp {
        @include wp(1200px);
    }

    &-wrapper {
        padding: px2rem(80px) 0;

    }

    &-content {
        font-size: 18px;
        color: $color666;
        line-height: 40px;
        border-top: 1px dashed rgba($color666, .4);
        padding-top: 40px;
        margin-top: 25px;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(32px);
            line-height: 2;
            padding-top: px2rem(50px);
        }
        img {
            max-width: 100%;
        }
    }

    &-value {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left top;
        height: 800px;
        box-sizing: border-box;
        padding: px2rem(80px) 0 0;
        @media screen and (max-width: 1024px) {
            background-size: auto 40%;
            height: auto;
            padding: px2rem(80px) 0 px2rem(100px);
        }
        .wp {
            @include wp(1260px);
        }

        &-content {
            display: flex;
            margin-top: 40px;
            position: relative;
            @media screen and (max-width: 1024px) {
                overflow: hidden;
                padding-bottom: px2rem(160px);
            }
        }
        &-pagination {
            position: absolute;
            width: 100%;
            text-align: center;
            .swiper-pagination-bullet {
                width: px2rem(15px);
                height: px2rem(5px);
                background-color: $color999;
                border-radius: px2rem(3px);
                opacity: 1;
                transition: all .3s;
                margin: 0 px2rem(7px);
            }

            .swiper-pagination-bullet-active {
                width: px2rem(20px);
                background-color: #c9030a;
            }
        }

        &-item {
            width: 420px;
            height: 420px;
            border-radius: 50%;
            background-color: #faf3f4;
            box-sizing: border-box;
            border: 3px solid #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            position: relative;
            z-index: 3;
            @media screen and (min-width: 1024px) {
                &:first-child {
                    left: 26px;
                    z-index: 2;
                }
                &:last-child {
                    right: 26px;
                    z-index: 5;
                }
            }
            @media screen and (max-width: 1024px) {
                width: 90vw;
                height: 90vw;
            }

            &:hover {
                .icon {
                    img {
                        transform: rotateY(180deg);
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-image: linear-gradient(to bottom, #faf3f4, transparent 20%);
                left: 0;
                top: calc(100% + 2px);
            }

            .icon {
                img {
                    transition: all .6s;
                }
            }

            .title {
                font-size: 35px;
                color: #000;
                font-weight: bold;
                margin-bottom: 11px;
                margin-top: 35px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(60px);
                    margin-top: px2rem(50px);
                }
            }

            .desc {
                font-size: 20px;
                color: $color666;
                line-height: 35px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(34px);
                    line-height: 1.8;
                }
            }
        }
    }
}